$(document).ready(function ($) {

	var bananaLines = $('.banana-lines-wrap');
	if(bananaLines.length > 0) {
		$(window).on('scroll load', function () {
			if(inViewport(bananaLines)) {
				bananaLines.removeClass('banana-lines-hidden');
			}
		});
	}

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}
