jQuery(function(){
	$('.colored-underline').each(function () {
	    var s = '<span class="underline">',
	        decoded;
	    $(this).prop('innerHTML', function (_, html) {
	        s += html.replace(/&amp;/g, '&').replace(/ /g, '\u00A0</span><span class="underline">');
	        s += '\u00A0</span>'
	        $(this).html(s);
	    });
	});
});