$(document).ready(function ($) {
	$('.to-anchor').scrollToAnchor();
});

// Plynulý scroll ke kotvě
(function ($) {
	$.fn.scrollToAnchor = function (settings) {

		settings = jQuery.extend({
			speed: 500
		}, settings);

		return this.each(function () {
			var caller = this;
			$(caller).click(function (event) {
				var elementClick = $(caller).attr("href");
				if (elementClick.length > 1 && elementClick.charAt(0) == '#') {
					event.preventDefault();

					var destination = $(elementClick).offset().top;
					destination = ($(window).width()>991) ? destination-$('.header').height() : destination;

					$("html:not(:animated),body:not(:animated)").animate(
							{scrollTop: destination},
							settings.speed, function () {
								history.pushState(null, null, elementClick);
							}
					);

					return false;
				}
			});
		});
	};
})(jQuery);
