 jQuery(function(){
 	var button = $('.sort-btn');

	$('.sort-placeholder').each(function() {
		var sortable = Sortable.create($(this)[0], {
			group: "items",
			onMove: function (evt, originalEvent) {
		        if($(evt.related).children().length > 0) {
		        	return false;
		        }
		    }
		});
	});

	button.on('click', function(e) {
		e.preventDefault();
		var items = $('.sort-item').clone(true),
			wrappers = $('.sort-items-sorted .sort-placeholder');
		if(items.length == wrappers.length) {
			$('.sort-item').remove();
			$(items).hide();

			wrappers.each(function(i, el){
				var item = items.filter('[data-order="' + (i+1) + '"]')[0];
				setTimeout(function(){
					$(wrappers[i]).append(item);
					$(item).fadeIn('fast');
				}, 120*i);
			});
		}
	});

 });