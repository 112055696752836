/* Scrollspy navigation */

$(function () {
	var sideMenu = $(".scrollspy");

	if (sideMenu.length) {
		// Cache selectors
		var lastId,
			sideMenuHeight = sideMenu.outerHeight(),
			// All list items
			menuItems = sideMenu.find("a"),
			// Anchors corresponding to menu items
			scrollItems = menuItems.map(function () {
				var selector = $(this).attr("href");
				if (selector.length < 1 || selector.charAt(0) != '#' || !$($(this).attr("href")).length) {
					return null;
				} else {
					return $($(this).attr("href"));
				}
			});

		// Bind to scroll
		$(window).bind('load scroll', function () {
			// Get container scroll position
			var fromTop = $(this).scrollTop() + sideMenuHeight + 50;

			// Get id of current scroll item
			var cur = scrollItems.map(function () {
				if ($(this).offset().top < fromTop)
					return this;
			});
			// Get the id of the current element
			cur = cur[cur.length - 1];
			var id = cur && cur.length ? cur[0].id : "";

			if (lastId !== id) {
				lastId = id;
				// Set/remove active class
				menuItems
						.parent().removeClass("active")
						.end().filter("[href=#" + id + "]").parent().addClass("active");
			}
		});
	}
});
