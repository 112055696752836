var disableHashChange = false;

$(document).ready(function ($) {

	/*$(window).on('hashchange', function () {
		var hash = location.hash;
		if (!disableHashChange && hash) {
			var formData = $.deparam(hash.substr(1));
			if (formData && formData.formId && $('#' + formData.formId).length) {
				$('#' + formData.formId).values(formData);
				$('#' + formData.formId).submitForm();
			} else if ($(jQuerySelectorEscape(hash)).length) {
				$(jQuerySelectorEscape(hash)).click();
			}
		}
		if (disableHashChange) {
			disableHashChange = false;
		}
	});

	$(window).trigger('hashchange');*/

});
