 jQuery(function(){
	var points = $('.link-point'),
		boxes = $('.link-box');

	var canvas = document.getElementById("canvas"),
		canvasWrapper = $('.canvas-wrap'),
		context = canvas.getContext("2d");

	context.lineWidth="2";
	context.strokeStyle="#fbc43a"; 
	context.setLineDash([7, 3]);; 

	points.each(function() {
		var point = $(this),
			boxSel = '#' + point.data('box'),
			box = $(boxSel),
			curve = {
				startLeft: point.data('left'),
				startTop: point.data('top'),
				mid1Left: point.data('mid1-left'),
				mid1Top: point.data('mid1-top'),
				mid2Left: point.data('mid2-left'),
				mid2Top: point.data('mid2-top'),
				endLeft: 600,
				endTop: 210
			}

		if(box.length > 0) {
			point.on('click', function() {
				var isActive = box.hasClass('link-box-active');

				boxes.removeClass('link-box-active');
				points.removeClass('link-point-active');
				context.clearRect(0, 0, canvas.width, canvas.height);
				if(!isActive) {
					context.beginPath(); 
					context.moveTo(curve.startLeft, curve.startTop);
					context.bezierCurveTo(curve.mid1Left, curve.mid1Top, curve.mid2Left, curve.mid2Top, curve.endLeft, curve.endTop);
					context.stroke();
					box.addClass('link-box-active');
					point.addClass('link-point-active');
					canvasWrapper.addClass('canvas-anim');
					setTimeout(function() {
						canvasWrapper.removeClass('canvas-anim');
					}, 1000);
				}
			});
		}
	});

	points.first().click();

	boxes.each(function() {
		var close = $(this).find('.link-box-close');
		close.on('click', function() {
			boxes.removeClass('link-box-active');
			points.removeClass('link-point-active');
			context.clearRect(0, 0, canvas.width, canvas.height);
		})
	})
 });