jQuery(function(){
	var earningsTrig = $('.earnings-trig'),
		earnings = $('.earnings'),
		mainnav = $('.main-nav'),
		openMenuTrig = $('.mobile-nav-trig');
	var mainNavTriggers = $('.main-nav-trig');
	
	earnings.css('right', mainnav.width());

	earningsTrig.on('click', function() {
		earnings.toggleClass('active');
	});

	/* dropdowns */
	mainNavTriggers.each(function() {
		var dropdownSel = '#' + $(this).data('dropdown'),
			dropdown = $(dropdownSel);
		if(dropdown.length > 0) {
			$(this).on('click', function() {
				var active = dropdown.hasClass('active');
				$('.main-nav-dropdown').removeClass('active');
				mainNavTriggers.removeClass('active');

				if(!active) {
					dropdown.addClass('active');
					$(this).addClass('active');
				}
			});
		}
	});

	/* mobile navigation */
	var body = $('body'),
		menuTrigger = $('.mobile-nav-trig');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	/* logo slide out to top */
	var logoOut = false,
		logo = $('.header-logo');
	$(window).bind('scroll', function () {
		var fromTop = $(this).scrollTop();
		if(!logoOut && fromTop > 80) {
			body.removeClass('header-logo-in');
			body.addClass('header-logo-out');
			logoOut = true;
		}
		else if(logoOut && fromTop < 80) {
			body.removeClass('header-logo-out');
			body.addClass('header-logo-in');
			logoOut = false;
		}
	});
});