 jQuery(function(){
	var seconds = 0;
	var timeCount = $('.count-time'),
		miguelCount = $('.count-miguel'),
		lidlCount = $('.count-lidl'),
		schwarzCount = $('.count-schwarz');
	var earningsWrap = $('.earnings'),
		lidlWrap = lidlCount.closest('li'),
		schwarzWrap = schwarzCount.closest('li');
	var earnings = {
		miguel: 0.0004451567,
		lidl: 194.5222533458,
		schwarz: 66.1764705882
	};

	setInterval(function() {
		seconds++;
		time = (seconds > 359) ? new Date(1000 * seconds).toISOString().substr(11, 8) : new Date(1000 * seconds).toISOString().substr(14, 5)
		timeCount.html(time);
		var miguel = Math.round(seconds*earnings.miguel*10)/10,
			lidl = Math.round(seconds*earnings.lidl),
			schwarz = Math.round(seconds*earnings.schwarz);
		if((lidl.toString().length > 4) && lidl.toString().length > lidlCount.html().toString().length) {
			lidlWrap.width(lidlWrap.closest('li').width() + 20);
			earningsWrap.width(earningsWrap.width() + 20);
		}
		if((schwarz.toString().length > 4) && schwarz.toString().length > schwarzCount.html().toString().length) {
			schwarzWrap.width(schwarzWrap.closest('li').width() + 20);
			earningsWrap.width(earningsWrap.width() + 20);
		}
		miguelCount.html(miguel);
		lidlCount.html(lidl);
		schwarzCount.html(schwarz);
	}, 1000);
 });