var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {
		// Gallery slider (first)
		$('.gallery-slider-1').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				gallerySlider1 = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: true,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					//customLink: '.room-slider .switch a',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
					initCallback: function() {
						loadOptimalizedSlideImages(gallerySlider1, 1);
					},
					beforeAnimation: function(t) {
						loadOptimalizedSlideImages(gallerySlider1, t);
					}
				});
			}
			else {
				loadOptimalizedSlideImages(null, null, $(this).find('.panel'));
			}
		});
		
		// Gallery slider (second)
		$('.gallery-slider-2').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				gallerySlider2 = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: true,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					//customLink: '.room-slider .switch a',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
					initCallback: function() {
						loadOptimalizedSlideImages(gallerySlider2, 1);
					},
					beforeAnimation: function(t) {
						loadOptimalizedSlideImages(gallerySlider2, t);
					}
				});
			}
			else {
				loadOptimalizedSlideImages(null, null, $(this).find('.panel'));
			}
		});
		
		// Story (fairtrade) slider
		$('.story-slider').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				storySlider = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: true,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					//customLink: '.room-slider .switch a',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
					initCallback: function() {
						loadOptimalizedSlideImages(storySlider, 1);
					},
					beforeAnimation: function(t) {
						loadOptimalizedSlideImages(storySlider, t);
					}
				});
				
				$(window).on('contentOpen', function() {
					// adjust slider when it is shown (adjust method doesnt work -> must resize window)
					//storySlider.adjust();
					$(window).resize();
				});
			}
			else {
				loadOptimalizedSlideImages(null, null, $(this).find('.panel'));
			}
		});
	}

});

function loadOptimalizedSlideImages(slider, slideNumber, slides)
{
	// load slides from slider they are not explicitly passed
	if (!slides && slider.length && slideNumber) {
		var slides = $([]);
		for (var i = slideNumber; i <= (parseInt(slideNumber) + parseInt(slider.getOption('slideCount'))); i++) {
			var slide = slider.getSlide(i);
			slides = slides.add(slide);
		}
	}

	// load the current slide image + the next one
	if (slides && slides.length) {
		slides.each(function() {
			var slide = $(this);
			// load videos from wysiwyg
			var videoElements = slide.find('[data-video-tag]');
			if (slide.attr('data-video-tag')) {
				videoElements = videoElements.add(slide);
			}
			videoElements.each(function() {
				var content = $($(this).attr('data-video-tag'));
				if ($(this).children().length == 0) {
					$(this).append(content);
				}
			});
			// load images as tag
			var tagImageElements = slide.find('[data-image-tag]');
			if (slide.attr('data-image-tag')) {
				tagImageElements = tagImageElements.add(slide);
			}
			tagImageElements.each(function() {
				if ($(this).find('img').length === 0) {
					$(this).prepend($(this).attr('data-image-tag'));
				}
			});
		});
	}
}